import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {DeleteProfileMedia, GetProfileMedia} from './profile-media.actions';
import {firstValueFrom} from 'rxjs';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';
import {
    errorHandler,
    httpErrorToString,
    IErrorHandlerArgs,
} from 'src/app/shared/helpers/error-handler';
import {ProfileMediaApiService} from 'src/app/shared/services/profile-media-api.service';
import {IProfileMedia} from 'src/app/interfaces/profile-media';

export interface IProfileMediaState {
    data: IProfileMedia[];
    loading: boolean;
    hasValue: boolean;
    error: any;
}

@State<IProfileMediaState>({
    name: 'profileMedia',
    defaults: {
        data: [],
        loading: false,
        hasValue: false,
        error: null,
    },
})
@Injectable()
export class ProfileMediaState {
    private readonly _errorHandleInitArgs: IErrorHandlerArgs = {
        error: null,
        appInsightsSrv: this.insight,
        scope: 'ProfileMediaState',
    };
    constructor(
        private insight: AppInsightsService,
        private profileMediaApi: ProfileMediaApiService,
        private store: Store
    ) { }

    @Selector()
    public static data(state: IProfileMediaState): IProfileMedia[] {
        return state.data;
    }

    @Selector()
    public static loading(state: IProfileMediaState): boolean {
        return state.loading;
    }

    @Selector()
    public static hasValue(state: IProfileMediaState): boolean {
        return state.hasValue;
    }

    @Selector()
    public static error(state: IProfileMediaState): any {
        return state.error;
    }

    @Action(GetProfileMedia)
    public async getProfileMedia(ctx: StateContext<IProfileMediaState>): Promise<IProfileMediaState> {
        ctx.patchState({error: null, loading: true});
        try {
            const medias = await firstValueFrom(this.profileMediaApi.getAllProfileMedia());
            
            ctx.patchState({
                data: medias,
                loading: false,
                hasValue: !!medias?.length,
            });
        } catch (error: any) {
            errorHandler({
                ...this._errorHandleInitArgs,
                error,
                fallbackMessage: 'An unknown error occurred during getting profile medias'
            });
            ctx.patchState({
                data: null,
                hasValue: false,
                loading: false,
                error: httpErrorToString(error),
            });
        }
        return ctx.getState();
    }

    @Action(DeleteProfileMedia)
    public async deleteProfileMedia(ctx: StateContext<IProfileMediaState>, {mediaId}: DeleteProfileMedia):  Promise<IProfileMediaState> {
        ctx.patchState({loading: true});
        try {
            await firstValueFrom(this.profileMediaApi.deleteWithoutHandlingError(mediaId));
            ctx.patchState({loading: false});
            this.store.dispatch(new GetProfileMedia());
        } catch (error: any) {
            errorHandler({
                ...this._errorHandleInitArgs,
                error,
                fallbackMessage: 'An unknown error occurred during getting profile medias'
            });
            ctx.patchState({loading: false, error: httpErrorToString(error),});
        }
        return ctx.getState();
    }
}
